<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card class="pa-6">
          <v-card-title class="">
            Wishlist Purpose Product Details
            <v-spacer></v-spacer> </v-card-title
          ><br />
          <!-- <b-container v-if="report"> -->
          <div v-if="user">
            <v-data-table
              :headers="headers"
              :items="user"
              hide-default-footer
              class="elevation-1"
            >
              <template v-slot:item.product="{ item }">
                <v-img
                  width="80px"
                  height="80px"
                  contain
                  :src="mediaURL + item.product.photos[0]"
                >
                </v-img>
              </template>
              <template v-slot:item.productname="{ item }">
                <span>{{ item.product.productname }}</span>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      user: [],
      photos: [],
      seller: [],
      subcat: [],
      productStatus: false,
      product: [],
      productArray: [],
      categoryArray: [],
      msg: null,
      widthOfCard: "250px",
      appLoading: false,
      dialog: false,
      userStatus: null,
      userRole: localStorage.getItem("role"),
      tab: null,
      text: "",
      reviewDialog: false,
      approveDialog: false,
      rejectDialog: false,
      reason: "",
      docs: [],
      doc: null,
      documents: "",
      docDialog: false,
      navItems: [
        { name: "Products", type: null },
        { name: "Offers", type: "Offer" },
        { name: "Deals", type: "Deals" },
      ],
      headers: [
        { text: "Photo", value: "product" },
        { text: "Product Name", value: "productname" },
        { text: "Total Price", value: "totalprice" },
      ],
      currentType: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/wishlist/user/purpose/adminget",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.userId,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.user = response.data.products;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          // this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-size: 14px;
}
.item_value {
  font-size: 13px;
}
</style>
